(function ($, Promise) {

  // Constructor
  function Fusee(id) {

    // Save game id
    this.id = id;

    this.data = false;
    this.worldData = false;

    this.debug = true;

    this.mode = "master";

    this.fullSceneWidth = (1920 + 1080) * 2;
    this.fullSceneHeight = 1080;
    this.camera = false;
    this.cameraFOV = 20;
    this.cameraFar = 5000000; // distance where the elements start to clip
    this.scene = false;
    this.renderer = false;
    this.stats = false;
    this.background = false;
    this.bgRotationSpeedFactor = 0.00025; // smaller = slower
    this.direction = {x: 0, y: 0};

    this.screenBlurAmount = 0;
    this.blurPass = false;
    this.composer = false;

    this.debris = false;
    this.planets = false;
    this.ships = false;
    this.meteorites = false;

    this.sync = false;

    this.baseGlobalSpeedFactor = 100;
    this.globalSpeedFactor = this.baseGlobalSpeedFactor;
    this.globalHyperSpeedFactor = 1000;
    this.hyperSpeedBlur = 0.95;

    this.hyperSpeedTimeline = false;
    this.timeWrapTimeline = false;

    this.controlFrame = false;

    this.ui = {
      cadran1: false,
      cadran2: false,
      cadran3: false,
    }

    this.alertTimeout = false;
    this.alertPlanetDistance = 15000; // the smaller the value, the nearer the planet needs to be to the camera to trigger the alert

    this.videoFrameTimeline = false;
    this.$videoElement = $("#video-container video");
    this.$captionElement = $("#video-container #caption")
    this.$videoFrame = $("#video-container");
    this.$dashboard = $("#main-dashboard");
    this.$dashboardAlert = $("#dashboard-alert-overlay");

    // Load data then init
    this.loadData();
  }

  Fusee.prototype.loadData = function () {

    // Load JSON
    var json1 = $.ajax({
      url: "data/fusee-" + this.id + ".json",
      dataType: 'json',
      cache: false,
      complete: $.proxy(function (data) {
        this.data = JSON.parse(data.responseText);
        this.mode = this.data.mode;
      }, this)
    });

    // Load JSON
    var json2 = $.ajax({
      url: "data/world.json",
      dataType: 'json',
      cache: false,
      complete: $.proxy(function (data) {
        this.worldData = JSON.parse(data.responseText);
      }, this)
    });

    // Wait for all
    Promise.all([
      json1.promise(),
      json2.promise(),
    ]).then($.proxy(function () {
      this.initGame();
    }, this));

  };

  Fusee.prototype.initGame = function () {

    this.debug = this.data.debug;

    this.initScene();

    this.audio = new AudioPlayer(this);

    this.debris = new Debris(this);
    this.planets = new Planets(this);
    this.meteorites = new Meteorites(this);
    this.ships = new Ships(this);

    this.closeVideoPlayer();

    if (this.debug) {
      this.initDebugGui();
    }

    if (this.data.flipHorizontal) {
      $("#main-dashboard").addClass("flipped");
    }

    this.ui.cadran1 = new UIElement("ui-speed", "images/main-ui-speed.svg");
    $("#ui-container").append(this.ui.cadran1.domElement);

    this.ui.cadran2 = new UIElement("ui-vertical", "images/main-ui-vertical.svg");
    $("#ui-container").append(this.ui.cadran2.domElement);

    this.ui.cadran3 = new UIElement("ui-counter", "images/main-ui-counter.svg");
    $("#ui-container").append(this.ui.cadran3.domElement);

    // Open control frame
    this.openControlFrame();

    // Start websocket server and sync
    this.sync = new Sync(this);

    // Keyboard events
    $(window).bind("keydown", $.proxy(function (e) {

      if (e.keyCode == 27 && gui) {
        this.quit(); // Escape
      } else if ((e.keyCode == 73 || e.keyCode == 67) && gui) {
        nw.Window.get().showDevTools(); // Key I or C
      } else if (e.keyCode == 87) {
        this.updateDirection({x: 0, y: -1});  // Key W
      } else if (e.keyCode == 83) {
        this.updateDirection({x: 0, y: 1}); // Key S
      } else if (e.keyCode == 65) {
        this.updateDirection({x: -1, y: 0}); // Key S
      } else if (e.keyCode == 68) {
        this.updateDirection({x: 1, y: 0}); // Key D
      } else if (e.keyCode == 68) {
        this.updateDirection({x: 1, y: 0}); // Key D
      } else if (e.keyCode == 81) {
        this.triggerHyperSpeed() // Key Q
      } else if (e.keyCode == 69) {
        this.triggerTimeWrap(); // Key E
      }
    }, this)).bind("keyup", $.proxy(function (e) {

      if ($.inArray(e.keyCode, [87, 83, 65, 68]) !== -1) {
        this.updateDirection({x: 0, y: 0});  // Key WASD
      }
    }, this));

    // Debug purpose
    // setInterval($.proxy(function () {
    //   this.playVideo("videos/rover.mp4");
    // }, this), 5000);

    this.animate();
  };

  Fusee.prototype.openControlFrame = function () {

    var x = this.data.controlframe.x;
    var y = this.data.controlframe.y;

    this.openFrame("control.html?p=" + this.id, 1080, 1920, x, y, $.proxy(function (new_win) {
      console.log("callback for controlFrameReady");
      this.controlFrame = new_win;
      this.controlFrame.app.initSceneAndRenderer(this, this.scene, this.renderer, this.cameraFOV, this.cameraFar, this.fullSceneWidth, this.fullSceneHeight);
      this.initControlFrameEventListener(new_win);

      // Trigger replace window
      setInterval($.proxy(this.replaceWindow, this, x, y), 5000);

    }, this));
  };

  Fusee.prototype.replaceWindow = function (x, y) {
    if (this.controlFrame != false) {
      // Set main size
      nw.Window.get().resizeTo(1920,1080);
      // Set control frame size and position
      nw.Window.get(this.controlFrame).resizeTo(1080, 1920);
      nw.Window.get(this.controlFrame).moveTo(x, y);
    } else {
      console.log("no nwjs window (" + x + "," + y + ")");
    }
  }

  Fusee.prototype.openFrame = function (url, width, height, x, y, callback) {

    if (typeof nw !== "undefined") {
      // Create a new window and get it
      nw.Window.open(url, {
        transparent: false,
        width: width,
        height: height,
        frame: false,
        focus: false,
        resizable: false,
        show: true,
        // always_on_top: true
        // new_instance: true // DOESN'T WORK TO DATE : https://github.com/nwjs/nw.js/issues/4418
      }, $.proxy(function (new_win) {
        new_win.on('loaded', $.proxy(function () {
          // new_win.moveTo(screen.width / 2 - $("body").width() / 2, index * 200);
          new_win.moveTo(x, y);
          new_win.resizeTo(width, height);
          window.addEventListener("message", $.proxy(function (event) {
            if (event.data == "ready") {
              callback(new_win.window);
            }
          }, this), false);
        }, this));
      }, this));

    } else {
      // console.log("menubar=no,scrollbar=no,resizable=no,status=no,titlebar=no,height=" + height + ",width=" + width + ",left=" + x + ",top=" + y)
      var new_win = window.open(url, "_blank", "menubar=no,scrollbar=no,resizable=no,status=no,titlebar=no,height=" + height + ",width=" + width + ",left=" + x + ",top=" + y);
      $(new_win).on('load', $.proxy(function () {
        new_win.moveTo(x, y);
        window.addEventListener("message", $.proxy(function (event) {
          if (event.data == "ready") {
            callback(new_win);
          }
        }, this), false);
      }, this));
    }
  };

  Fusee.prototype.initControlFrameEventListener = function () {

    window.addEventListener("message", $.proxy(function (event) {

      if (event.data.type == "video") {
        var id = event.data.id;
        var caption_fr = this.data["actions"][id].caption_fr;
        var caption_en = this.data["actions"][id].caption_en;

        this.playVideo(event.data.parameter, caption_fr, caption_en);

      } else if (event.data.type == "direction") {
        this.updateDirection(event.data.parameter);
        if (this.mode == "slave") {
          this.sync.slaveSendCommand("updateDirection", event.data.parameter);
        }

      } else if (event.data.type == "superaction" && event.data.parameter == "hyperspeed") {
        this.triggerHyperSpeed();
        if (this.mode == "slave") {
          this.sync.slaveSendCommand("triggerHyperSpeed");
        }

      } else if (event.data.type == "superaction" && event.data.parameter == "timewrap") {
        this.triggerTimeWrap();
        if (this.mode == "slave") {
          this.sync.slaveSendCommand("triggerTimeWrap");
        }
      }
    }, this), false);

    // Close all opened cameras when page is reloaded or closed
    window.onunload = $.proxy(function () {
      if (this.controlFrame) {
        this.controlFrame.close();
      }
    }, this);

  }

  Fusee.prototype.updateDirection = function (direction) {
    this.debris.updateDirection(direction);
    this.meteorites.updateDirection(direction);
    this.planets.updateDirection(direction);
    this.ships.updateDirection(direction);

    this.direction = direction;
  }

  Fusee.prototype.initScene = function () {
    this.camera = new THREE.PerspectiveCamera(this.cameraFOV, (1920 / 1080), 0.1, this.cameraFar); // fov, Asect ratio, near clipping, far clipping

    this.camera.position.x = 0;
    this.camera.position.y = 0;
    this.camera.position.z = 1000; // pull the camera back

    // Setup multiview
    this.camera.setViewOffset(this.fullSceneWidth, this.fullSceneHeight, this.data.cameras.main.offsetX, this.data.cameras.main.offsetY, 1920, 1080);

    this.scene = new THREE.Scene();
    this.scene.fog = new THREE.FogExp2(0x020820, 0.0000025); // depth of view

    var ambientLight = new THREE.AmbientLight(0x9895e7, 0.95);
    this.scene.add(ambientLight);

    var pointLight = new THREE.PointLight(0xA8A588, 1.2, 100);
    pointLight.position.set(0, 1000, -50);
    this.scene.add(pointLight);

    var pointLight = new THREE.PointLight(0xCA8602, 0.85, 1000);
    pointLight.position.set(0, 0, -10);
    this.scene.add(pointLight);

    // add the background
    // create the geometry sphere
    var bgGeometry = new THREE.SphereGeometry(90, 32, 32)
    // create the material, using a texture of startfield
    var bgMaterial = new THREE.MeshBasicMaterial()
    bgMaterial.map = new THREE.TextureLoader().load("images/bg2.jpg");
    bgMaterial.map.wrapS = THREE.RepeatWrapping;
    bgMaterial.map.wrapT = THREE.RepeatWrapping;
    bgMaterial.side = THREE.BackSide;
    bgMaterial.depthTest = false;
    // create the mesh based on geometry and material
    this.background = new THREE.Mesh(bgGeometry, bgMaterial);
    this.background.scale.set(10, 10, 10);

    this.scene.add(this.background);

    this.renderer = new THREE.WebGLRenderer({antialias: true, alpha: false});
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.setSize(1920, 1080);
    this.renderer.sortObjects = true;

    this.renderPass = new THREE.RenderPass(this.scene, this.camera);
    this.renderPass.setSize(1920 * 2, 1080 * 2);

    // this.fxaaPass = new THREE.ShaderPass(THREE.FXAAShader);
    // this.fxaaPass.renderToScreen = true;

    this.distortPass = new THREE.ShaderPass(THREE.DistorPass);
    this.distortPass.renderToScreen = true;

    this.blurPass = new THREE.AfterimagePass();
    this.blurPass.uniforms.damp.value = this.screenBlurAmount;
    this.blurPass.renderToScreen = false;

    this.composer = new THREE.EffectComposer(this.renderer);
    // this.composer.addPass(this.fxaaPass);
    this.composer.addPass(this.renderPass);
    this.composer.addPass(this.distortPass);
    this.composer.addPass(this.blurPass);

    document.body.appendChild(this.renderer.domElement);
  }

  Fusee.prototype.initDebugGui = function () {
    this.stats = new Stats();
    document.body.appendChild(this.stats.dom);

    var gui = new dat.GUI();
    var params = {
      speed: this.baseGlobalSpeedFactor,
      hyperSpeed: false,
      blur: 0,
    };

    var speed = gui.add(params, 'speed').min(1).max(this.globalHyperSpeedFactor).step(1).onChange($.proxy(function (value) {
      this.globalSpeedFactor = value;
    }, this));

    var blur = gui.add(params, 'blur').min(0).max(1).step(0.001).onChange($.proxy(function (value) {
      this.screenBlurAmount = value;
    }, this));

    var hyperSpeed = gui.add(params, 'hyperSpeed').onChange($.proxy(function (value) {
      console.log(value);
      speed.setValue(value ? this.globalHyperSpeedFactor : this.baseGlobalSpeedFactor);

      blur.setValue(value ? this.hyperSpeedBlur : 0);
    }, this));

    gui.open();
  }

  Fusee.prototype.triggerTimeWrap = function () {

    if (this.timeWrapTimeline != false) {
      this.timeWrapTimeline.kill();
    }

    // this.distortPass.uniforms.distortWave.value = (Math.sin(millis() * 0.005) + 1) / 2;

    this.audio.play(this.worldData.audio.timewrap);

    this.timeWrapTimeline = new TimelineMax();
    this.timeWrapTimeline.to(this.distortPass.uniforms.distortWave, 0.5, {
      value: 1,
      ease: Sine.easeInOut,
    });
    this.timeWrapTimeline.to(this.distortPass.uniforms.distortWave, 0.5, {
      value: 0,
      ease: Sine.easeInOut,
    });

  }

  Fusee.prototype.triggerHyperSpeed = function () {

    if (this.hyperSpeedTimeline != false) {
      this.hyperSpeedTimeline.kill();
    }

    this.audio.play(this.worldData.audio.hyperspeedIn);

    this.hyperSpeedTimeline = new TimelineMax();
    this.hyperSpeedTimeline.to(this, 0.35, {
      globalSpeedFactor: this.globalHyperSpeedFactor,
      screenBlurAmount: this.hyperSpeedBlur,
      ease: Sine.easeInOut,
    }, 0);
    this.hyperSpeedTimeline.to(this.background.position, 3.0, {
      z: 200,
      ease: Sine.easeOut
    }, 0);
    this.hyperSpeedTimeline.to(this, 1, {
      globalSpeedFactor: this.baseGlobalSpeedFactor,
      screenBlurAmount: 0,
      ease: Sine.easeInOut,
      onStart: $.proxy(function () {
        this.audio.play(this.worldData.audio.hyperspeedOut);
      }, this)
    }, 3);
    this.hyperSpeedTimeline.to(this.background.position, 1, {
      z: 0,
      ease: Sine.easeInOut,
    }, 3);

  }

  Fusee.prototype.animate = function () {

    requestAnimationFrame($.proxy(this.animate, this));

    // Link  UI cadran with variables
    var oscillator1 = (Math.sin(millis() / 500) + 1) / 2;
    this.ui.cadran1.setProgress((this.globalSpeedFactor / (this.baseGlobalSpeedFactor * 2.25)) + (oscillator1 * 0.1));
    var oscillator2 = (Math.sin(millis() / 700));
    this.ui.cadran2.setProgress(map(this.direction.y, -1, 1, 0, 1) + (oscillator2 * 0.05));
    var oscillator3 = (Math.sin(millis() / 900) + 1) / 2;
    this.ui.cadran3.setProgress(map(this.direction.x, -1, 1, 0, 1) + (oscillator3 * 0.05));

    // update background rotation
    // this.background.rotation.y += direction.x * this.bgRotationSpeedFactor;
    // this.background.rotation.x += direction.y * this.bgRotationSpeedFactor;
    this.background.material.map.offset.x -= this.direction.x * this.bgRotationSpeedFactor + 0.0000279;
    this.background.material.map.offset.y -= this.direction.y * this.bgRotationSpeedFactor + 0.0000496;

    this.debris.animate();
    this.meteorites.animate();
    this.ships.animate();
    this.planets.animate();

    this.checkForNearPlanets();

    this.render();

    if (this.debug) {
      this.stats.update();
    }

  }

  Fusee.prototype.render = function () {

    // Update uniforms
    this.blurPass.uniforms.damp.value = this.screenBlurAmount;
    this.distortPass.uniforms.distortForce.value = 0.1
    // this.distortPass.uniforms.distortWave.value = (Math.sin(millis() * 0.005) + 1) / 2;

    // Blur pass is low quality.
    // Disable it and let distort pass render to screen when not required
    this.blurPass.enable = (this.screenBlurAmount >= 0.001);
    this.distortPass.enable = (this.blurPass.enable || this.distortPass.uniforms.distortWave.value >= 0.001);

    // Update wich pass is rednered to screen
    if (this.blurPass.enable == false && this.distortPass.enable == false) {
      this.renderPass.renderToScreen = true;
      this.distortPass.renderToScreen = false;
      this.blurPass.renderToScreen = false;
    } else if (this.blurPass.enable == false && this.distortPass.enable == true) {
      this.renderPass.renderToScreen = false;
      this.distortPass.renderToScreen = true;
      this.blurPass.renderToScreen = false;
    } else if (this.distortPass.enable == true) {
      this.renderPass.renderToScreen = false;
      this.distortPass.renderToScreen = false;
      this.blurPass.renderToScreen = true;
    }

    // Request render
    this.composer.render();
    // this.renderer.render(this.scene, this.camera);
  }

  Fusee.prototype.checkForNearPlanets = function () {
    for (var i = 0; i < this.planets.planets.length; i++) {

      if (this.planets.planets[i].position.z > this.camera.position.z) {
        continue;
      }

      // Let's check if the planet nearly touches our camera
      // We need to take in account the planets radius to have a good representation
      var distPlanetCenterToCamera = window.dist3D(this.camera.position, this.planets.planets[i].position);

      if (distPlanetCenterToCamera - this.planets.planets[i].scale.x <= this.alertPlanetDistance) {
        this.activateAllAlert();
      }
    }
  }

  Fusee.prototype.playVideo = function (url, caption_fr, caption_en) {

    this.closeVideoPlayer($.proxy(function () {

      this.$videoElement.attr("src", url);
      this.$videoElement.get(0).currentTime = 0;
      this.$videoElement.get(0).play();

      this.$captionElement.empty();
      this.$captionElement.append(caption_fr + "<br/>");
      this.$captionElement.append(caption_en);

      this.openVideoPlayer($.proxy(function () {

      }, this));
    }, this));


  }

  Fusee.prototype.openVideoPlayer = function (callback) {

    if (this.$videoFrame.hasClass("visible")) {
      // Already visible, trigger callback
      if (typeof callback == "function" && callback) {
        callback();
      }
      return;
    }

    // Close Video Player when video end
    $(this.$videoElement.get(0)).off('ended').on('ended', $.proxy(this.closeVideoPlayer, this));

    // Update class
    this.$videoFrame.addClass("visible")

    this.videoFrameTimeline = new TimelineMax();
    this.videoFrameTimeline.to(this.$videoFrame, 0.5, {
      y: "0",
      rotationX: "0deg",
      opacity: 1,
      ease: Sine.easeInOut,
      onComplete: (callback) ? $.proxy(callback, this) : false,
    });

    var $loadingBlocks = shuffle(this.$videoFrame.find(".loading-block"));
    for (var i = 0; i < $loadingBlocks.length; i++) {
      this.videoFrameTimeline.fromTo($loadingBlocks.eq(i), 0.4, {
        rotationX: "0deg",
        opacity: 0.95,
      }, {
        rotationX: "90deg",
        opacity: 0,
        ease: RoughEase.ease.config({
          template: Power0.easeNone,
          strength: 1,
          points: 20,
          taper: "none",
          randomize: true,
          clamp: false
        }),
      }, "-=0.35");
    }

  }

  Fusee.prototype.closeVideoPlayer = function (callback) {

    if (!this.$videoFrame.hasClass("visible")) {
      // Already hiddem, trigger callback
      if (typeof callback == "function" && callback) {
        callback();
      }
      return;
    }

    // Update class
    this.$videoFrame.removeClass("visible")

    this.videoFrameTimeline = new TimelineMax();
    this.videoFrameTimeline.to(this.$videoFrame, 0.5, {
      y: "-100",
      rotationX: "90deg",
      opacity: 0,
      ease: Sine.easeInOut,
      onComplete: $.proxy(function () {

        // Pause video element
        this.$videoElement.get(0).pause();
        this.$videoElement.attr("src", "");
        this.$videoElement.get(0).load();

        // Trigger callback
        if (typeof callback == "function" && callback) {
          callback();
        }
      }, this),
    });

  }

  Fusee.prototype.activateAllAlert = function () {

    if (this.$dashboard.hasClass("alert-activated")) {
      return;
    }

    console.log("activateAllAlert")

    // Update class
    this.$dashboard.addClass("alert-activated");
    this.controlFrame.app.$control.addClass("alert-activated");

    this.audio.play(this.worldData.audio.alert);

    this.dashboardAlertTimeline = new TimelineMax();
    this.dashboardAlertTimeline.fromTo([this.$dashboardAlert, this.controlFrame.app.$controlAlert], 0.75, {
      opacity: 0,
    }, {
      opacity: 0.8,
      repeat: 3,
      yoyo: true,
    });
    this.dashboardAlertTimeline.to([this.$dashboardAlert, this.controlFrame.app.$controlAlert], 0.75, {
      opacity: 0,
      onComplete: $.proxy(this.deactivateAllAlert, this)
    });

  }

  Fusee.prototype.deactivateAllAlert = function () {

    console.log("deactivateAllAlert")

    if (!this.$dashboard.hasClass("alert-activated")) {
      return;
    }

    // Update class
    this.$dashboard.removeClass("alert-activated");
    this.controlFrame.app.$control.removeClass("alert-activated");

    // Kill Animation
    this.dashboardAlertTimeline.kill();
    this.dashboardAlertTimeline = false
  }

  Fusee.prototype.quit = function () {

    // Close syn server/client
    this.sync.close();

    // Close control frame
    if (this.controlFrame) {
      this.controlFrame.close();
    }

    // Request app quit
    if (gui) {
      gui.App.quit();
    }
  };

  Fusee.prototype.reset = function () {

  };

// Expose Fusee
  window.Fusee = Fusee;

})
(jQuery, Promise);
