(function ($, Promise) {

  // Constructor
  function Control(id) {

    this.main = false;

    // Save game id
    this.id = id;

    this.debug = true;

    this.renderer = false;
    this.camera = false;
    this.scene = false;
    this.canvas = $("canvas")[0];
    this.mousedown = false;

    this.composer = false;
    this.blurPass = false;

    this.$control = $("#game-fusee-control");
    this.$controlAlert = $("#control-alert-overlay");

    // Load data then init
    this.loadData();
  }

  Control.prototype.loadData = function () {

    // Load JSON
    var json1 = $.ajax({
      url: "data/fusee-" + this.id + ".json",
      dataType: 'json',
      cache: false,
      complete: $.proxy(function (data) {
        this.data = JSON.parse(data.responseText);
      }, this)
    });

    // Wait for all
    Promise.all([
      json1.promise(),
    ]).then($.proxy(function () {
      this.initGame();
    }, this));

  };

  Control.prototype.initGame = function () {

    this.debug = this.data.debug;
    var supportsTouch = 'ontouchstart' in window || navigator.msMaxTouchPoints;

    // Create actions buttons
    $.each(this.data.actions, $.proxy(function (i, n) {
      $("#actions-buttons-container").append("<div class='action-button' data-type='" + n.type + "' data-parameter='" + n.parameter + "' data-id='" + i + "'><img src='" + n.icon + "'/></div>")
    }, this));

    // Bind actions
    $(document).on((supportsTouch) ? "touchstart" : "click", ".action-button", $.proxy(function (e) {
      var type = $(e.currentTarget).data("type");
      var parameter = $(e.currentTarget).data("parameter");
      var id = $(e.currentTarget).data("id");
      this.sendMessage(type, parameter, id);
    }, this));

    // Init direction control
    this.initDirectionControl();

    if (window.opener) {
      window.opener.postMessage("ready", "*");
    }

    // Keyboard events
    $(window).bind("keydown", $.proxy(function (e) {
      if (e.keyCode == 27 && window.opener) {
        window.opener.app.quit();
      }
    }, this));

  };


  Control.prototype.initDirectionControl = function () {

    $(document).on("touchmove mousemove", "#direction-container", $.proxy(function (e) {

      var isTouch = (e.type != "mousemove");

      if (!isTouch && this.mousedown == false) {
        return;
      } else {
        this.mousedown = true;
      }

      var x = (isTouch ? e.originalEvent.touches[0].pageX : e.pageX) - $(e.currentTarget).offset().left;
      var y = (isTouch ? e.originalEvent.touches[0].pageY : e.pageY) - $(e.currentTarget).offset().top;
      var trackWidth = $(e.currentTarget).width();
      var trackHeight = $(e.currentTarget).width();
      var normalizedX = constrain((((x / trackWidth) * 2) - 1), -1, 1);
      var normalizedY = constrain((((y / trackHeight) * 2) - 1), -1, 1);

      if (Math.sqrt(Math.pow(normalizedX, 2) + Math.pow(normalizedY, 2)) > 1) {
        var radians = Math.atan2(normalizedY, normalizedX)
        normalizedX = Math.cos(radians);
        normalizedY = Math.sin(radians);
      }

      // if (Math.abs(normalizedX) > Math.abs(normalizedY)) {
      //
      //   // Update joystick position
      //   $("#direction-joystick").css({
      //     left: ((normalizedX + 1) / 2 * 100) + "%",
      //     top: "50%",
      //   });
      //
      //   // Send message to master
      //   this.sendMessage("direction", {x: normalizedX, y: 0})
      //
      // } else {
      //
      //   // Update joystick position
      //   $("#direction-joystick").css({
      //     left:((normalizedX + 1) / 2 * 100) + "%",
      //     top: ((normalizedY + 1) / 2 * 100) + "%",
      //   });
      //
      //   // Send message to master
      //   this.sendMessage("direction", {x: 0, y: normalizedY})
      // }

      $("#direction-joystick").css({
        left: ((normalizedX + 1) / 2 * 100) + "%",
        top: ((normalizedY + 1) / 2 * 100) + "%",
      });

      this.sendMessage("direction", {x: normalizedX, y: normalizedY})

    }, this)).on("touchend mouseup", $.proxy(function (e) {


      // Update joystick position
      $("#direction-joystick").css({
        left: "50%",
        top: "50%",
      });

      // Send message to master
      if (this.mousedown) {
        this.sendMessage("direction", {x: 0, y: 0})
      }

      this.mousedown = false;

    }, this)).on("mousedown touchstart", "#direction-container", $.proxy(function (e) {

      this.mousedown = true;

    }, this));

  }

  Control.prototype.initSceneAndRenderer = function (p, scene, renderer, fov, far, fullSceneWidth, fullSceneHeight) {
    this.main = p;

    // Save given scene and camera
    this.scene = scene;
    this.fullSceneWidth = fullSceneWidth;
    this.fullSceneHeight = fullSceneHeight;

    this.camera = new THREE.PerspectiveCamera(fov, (1080 / 1080), 0.1, far); // fov, Asect ratio, near clipping, far clipping

    this.camera.position.x = 0;
    this.camera.position.y = 0;
    this.camera.position.z = 1000; // pull the camera back

    // Setup multiview
    this.camera.setViewOffset(this.fullSceneWidth, this.fullSceneHeight, this.data.cameras.control.offsetX, this.data.cameras.control.offsetY, 1080, 1080);

    // Init new renderer
    this.renderer = new THREE.WebGLRenderer({antialias: true, alpha: true, canvas: this.canvas});
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.setSize(1080, 1080);
    this.renderer.sortObjects = true;

    this.renderPass = new THREE.RenderPass(this.scene, this.camera);
    this.renderPass.setSize(1080 * 2, 1080 * 2);

    this.distortPass = new THREE.ShaderPass(THREE.DistorPass);
    this.distortPass.renderToScreen = true;

    this.blurPass = new THREE.AfterimagePass();
    this.blurPass.uniforms.damp.value = this.screenBlurAmount;
    this.blurPass.renderToScreen = false;

    this.composer = new THREE.EffectComposer(this.renderer);
    this.composer.addPass(this.renderPass);
    this.composer.addPass(this.distortPass);
    this.composer.addPass(this.blurPass);

    // Init render loop
    this.animate();
  }

  // Control.prototype.controlAlertActivated = function (callback) {
  //   if (this.$control.hasClass("alert-activated")) {
  //     return;
  //   }
  //
  //   // Update class
  //   this.$control.addClass("alert-activated");
  //
  //   this.controlAlertTimeline = new TimelineMax();
  //
  //   this.controlAlertTimeline.to(this.$controlAlert, 0.75, {
  //     opacity: 0.6,
  //     yoyo: true,
  //     repeat: -1,
  //   });
  //
  //   // To do when function end
  //   if (typeof callback == "function" && callback) {
  //
  //   }
  // }
  //
  // Control.prototype.controlAlertDeactivated = function (callback) {
  //   if (!this.$control.hasClass("alert-activated")) {
  //     return;
  //   }
  //
  //   // Update class
  //   this.$control.removeClass("alert-activated");
  //
  //   // Kill Animation
  //   this.controlAlertTimeline.kill();
  //   this.$controlAlert.animate({'opacity': '0'}, 1000);
  //
  //   // To do when function end
  //   if (typeof callback == "function" && callback) {
  //
  //   }
  // }

  Control.prototype.animate = function () {
    requestAnimationFrame($.proxy(this.animate, this));
    this.render();
  }


  Control.prototype.render = function () {

    // Sync shaders values with main
    this.blurPass.uniforms.damp.value = this.main.blurPass.uniforms.damp.value;
    this.distortPass.uniforms.distortForce.value = this.main.distortPass.uniforms.distortForce.value;
    this.distortPass.uniforms.distortWave.value = this.main.distortPass.uniforms.distortWave.value;

    // Syc render passes
    this.renderPass.enable = this.main.renderPass.enable
    this.renderPass.renderToScreen = this.main.renderPass.renderToScreen
    this.blurPass.enable = this.main.blurPass.enable
    this.blurPass.renderToScreen = this.main.blurPass.renderToScreen
    this.distortPass.renderToScreen = this.main.distortPass.renderToScreen

    // Request render
    this.composer.render();
    // this.renderer.render(this.scene, this.camera);
  }

  Control.prototype.sendMessage = function (type, parameter, id) {
    console.info("Send message to main frame (todo)", type, parameter, id)

    if (window.opener) {
      window.opener.postMessage({
        type: type,
        parameter: parameter,
        id: id,
      }, "*");
    }

  };

  Control.prototype.reset = function () {

  };


  // Expose Control
  window.Control = Control;

})(jQuery, Promise);
