(function ($, Promise) {

  // Constructor
  function Sync(p) {

    this.p = p;
    this.data = p.data;
    this.mode = p.data.mode;

    this.server = false;
    this.client = false;

    // Start websocket server and sync
    if (typeof nw !== "undefined") {
      this.initSync();
    }

    Noty.setMaxVisible(15);
  }

  Sync.prototype.initSync = function () {


    if (this.mode == "master") {

      // Server
      this.server = require('socket.io')();
      this.server.sockets.on('connection', $.proxy(function (client) {
        this.log('Got connection from slave.', 'success');
        client.on('slaveCommand', $.proxy(function (data) {
          if (data.eventParams) {
            this.p[data.eventName](data.eventParams);
          } else {
            this.p[data.eventName]();
          }
        }, this));
      }, this));
      this.server.listen(this.data.link.port);

      // Log
      this.log("Init server on port " + this.data.link.port + ".", "info")

      // Sync all object once in a while
      setInterval($.proxy(function () {
        this.server.sockets.emit('syncValues', this.sendSyncValues());
      }, this), 500);

      // Sync most important values as quick as possible
      setInterval($.proxy(function () {
        this.server.sockets.emit('syncValues', this.sendSyncValues({
          syncMetorites: false,
          syncPlanets: false,
          syncShips: false,
        }));
      }, this), 20);

    } else {

      var host = this.data.link.ip + ':' + this.data.link.port;

      this.log("Init client. Connect to " + host, "info");

      // Client
      this.client = require('socket.io-client')("http://" + host, {
        autoConnect: true,
      });
      this.client.on('connect', $.proxy(function (data) {
        this.log('Connected to server', 'success');
      }, this));
      this.client.on('connect_error', $.proxy(function (data) {
        // this.log('Connection error.', 'error');
      }, this));
      this.client.on('syncValues', $.proxy(function (data) {
        this.receiveSyncValues(data)
      }, this));
      this.client.on('disconnect', $.proxy(function (data) {
        this.log('Connection disconnected.', 'error');
      }, this));
    }
  };


  Sync.prototype.slaveSendCommand = function (eventName, eventParams) {
    this.client.emit("slaveCommand", {eventName: eventName, eventParams: eventParams});
  }

  Sync.prototype.sendSyncValues = function (options) {

    var options = $.extend(options, {
      syncMetorites: true,
      syncPlanets: true,
      syncShips: true,
    })

    var output = {
      randomMti: randomSeed.mti,
      globalSpeedFactor: this.p.globalSpeedFactor,
      screenBlurAmount: this.p.screenBlurAmount,
      bgRotation: this.p.background.rotation,
      bgPosition: this.p.background.position,
      bgTextureOffset: this.p.background.material.map.offset,
      distortWave: this.p.distortPass.uniforms.distortWave.value,
      direction: this.p.direction,
      moveDebris: {
        x: this.p.debris.moveDebrisX,
        y: this.p.debris.moveDebrisY
      },
      moveMeteorites: {
        x: this.p.meteorites.moveMeteoritesX,
        y: this.p.meteorites.moveMeteoritesY
      },
      movePlanets: {
        x: this.p.planets.movePlanetsX,
        y: this.p.planets.movePlanetsY
      },
      moveShips: {
        x: this.p.ships.moveShipsX,
        y: this.p.ships.moveShipsY
      },
      meteorites: [],
      planets: [],
      ships: [],
    };

    if (options.syncMetorites == true) {
      for (var i = 0; i < this.p.meteorites.meteorites.length; i++) {
        output.meteorites.push(this.getSyncValuesForObject(this.p.meteorites.meteorites[i]));
      }
    }

    if (options.syncPlanets == true) {
      for (var i = 0; i < this.p.planets.planets.length; i++) {
        output.planets.push(this.getSyncValuesForObject(this.p.planets.planets[i]));
      }
    }

    if (options.syncShips == true) {
      for (var i = 0; i < this.p.ships.ships.length; i++) {
        output.ships.push(this.getSyncValuesForObject(this.p.ships.ships[i]));
      }
    }

    return output;
  }

  Sync.prototype.receiveSyncValues = function (data) {

    // Sync global values
    randomSeed.mti = data.randomMti;
    this.p.globalSpeedFactor = data.globalSpeedFactor;
    this.p.screenBlurAmount = data.screenBlurAmount;
    this.p.distortPass.uniforms.distortWave.value = data.distortWave;

    this.p.direction.x = data.direction.x;
    this.p.direction.y = data.direction.y;
    this.p.direction.z = data.direction.z;

    this.p.background.position.x = data.bgPosition.x;
    this.p.background.position.y = data.bgPosition.y;
    this.p.background.position.z = data.bgPosition.z;
    this.p.background.material.map.offset.x = data.bgTextureOffset.x;
    this.p.background.material.map.offset.y = data.bgTextureOffset.y;

    // Sync debris
    this.p.debris.moveDebrisX = data.moveDebris.x;
    this.p.debris.moveDebrisY = data.moveDebris.y;

    // Sync metorites
    this.p.meteorites.moveMeteoritesX = data.moveMeteorites.x;
    this.p.meteorites.moveMeteoritesY = data.moveMeteorites.y;
    // for (var i = 0; i < this.p.meteorites.meteorites.length; i++) {
    //   this.setSyncValuesForObject(this.p.meteorites.meteorites[i], data.meteorites[i]);
    // }

    // Sync planets
    this.p.planets.movePlanetsX = data.movePlanets.x;
    this.p.planets.movePlanetsY = data.movePlanets.y;
    for (var i = 0; i < this.p.planets.planets.length; i++) {
      this.setSyncValuesForObject(this.p.planets.planets[i], data.planets[i]);
    }

    // Sync ships
    this.p.ships.moveShipsX = data.moveShips.x;
    this.p.ships.moveShipsY = data.moveShips.y;
    for (var i = 0; i < this.p.ships.ships.length; i++) {
      this.setSyncValuesForObject(this.p.ships.ships[i], data.ships[i]);
    }
  }

  Sync.prototype.getSyncValuesForObject = function (object) {

    var output = {
      position: {
        x: object.position.x,
        y: object.position.y,
        z: object.position.z
      },
      rotation: {
        x: object.rotation.x,
        y: object.rotation.y,
        z: object.rotation.z
      }
    };

    if (typeof object.isShip !== "undefined" && object.isShip == true) {
      output.isShip = true;
      output.rotationDirection = object.rotationDirection;
      output.action = object.action;
    }

    return output
  };

  Sync.prototype.setSyncValuesForObject = function (object, values) {
    object.position.x = values.position.x;
    object.position.y = values.position.y;
    object.position.z = values.position.z;
    object.rotation.x = values.rotation.x;
    object.rotation.y = values.rotation.y;
    object.rotation.z = values.rotation.z;

    if (typeof values.isShip !== "undefined" && values.isShip == true) {
      object.isShip = true;
      object.rotationDirection = values.rotationDirection;
      object.action = values.action;
    }
  };

  Sync.prototype.close = function () {

  };

  Sync.prototype.reset = function () {

    if(this.server) {
      this.server.close();
    }

    if(this.client) {
      this.client.off();
      this.client.disconnect();
    }
  };

  Sync.prototype.log = function (text, type) {

    console.log(text);

    new Noty({
      text: text,
      type: type,
      layout: 'topLeft',
      theme: 'mint',
      timeout: 2000,
      progressBar: true,
    }).show();

  };


  // Expose Sync
  window.Sync = Sync;

})(jQuery, Promise);
