(function ($, Promise) {

  // Constructor
  function Planets(p) {

    this.p = p;

    this.planets = [];
    this.textures = [];
    this.materials = [];

    this.movePlanetsX = 0;
    this.movePlanetsY = 0;

    this.moveSpeedFactor = 2;
    this.speedFactor = 10;

    this.posMinX = -50000;
    this.posMaxX = 50000;
    this.posMinY = -10000;
    this.posMaxY = 10000;

    this.boundaryMinX = -100000;
    this.boundaryMaxX = 100000;
    this.boundaryMinY = -50000;
    this.boundaryMaxY = 50000;

    this.posMaxZ = this.p.camera.position.z;

    this.baseScaleFactor = 10000;
    this.distanceFactor = 6000;

    // Load data then init
    this.loadData();
  }

  Planets.prototype.loadData = function () {
    console.log("Load planets data");

    var promises = [];

    // Load materials
    for (var i = 0; i < this.p.worldData.planets.length; i++) {

      this.textures[i] = {
        color: false,
        specular: false,
        bump: false
      }

      // Load colors
      if (this.p.worldData.planets[i].texture.color != "" || this.p.worldData.planets[i].texture.color != false) {
        promises.push(new Promise($.proxy(function (index, resolve, reject) {
          // Load textures
          new THREE.TextureLoader().load(this.p.worldData.planets[index].texture.color,
            // onLoad callback
            $.proxy(function (texture) {
              this.textures[index].color = texture;
              resolve();
            }, this),
            undefined,
            function (err) {
              reject('An error happened while loading planet color texture.', err);
            });
        }, this, i)));
      }

      // Load specular
      if (this.p.worldData.planets[i].texture.specular != "" || this.p.worldData.planets[i].texture.specular != false) {
        promises.push(new Promise($.proxy(function (index, resolve, reject) {
          // Load textures
          new THREE.TextureLoader().load(this.p.worldData.planets[index].texture.specular,
            // onLoad callback
            $.proxy(function (texture) {
              this.textures[index].specular = texture;
              resolve();
            }, this),
            undefined,
            function (err) {
              reject('An error happened while loading planet specular texture.', err);
            });
        }, this, i)));
      }

      // Load bump
      if (this.p.worldData.planets[i].texture.bump != "" || this.p.worldData.planets[i].texture.bump != false) {
        promises.push(new Promise($.proxy(function (index, resolve, reject) {
          // Load textures
          new THREE.TextureLoader().load(this.p.worldData.planets[index].texture.bump,
            // onLoad callback
            $.proxy(function (texture) {
              this.textures[index].bump = texture;
              resolve();
            }, this),
            undefined,
            function (err) {
              reject('An error happened while loading planet bump texture.', err);
            });
        }, this, i)));
      }
    }

    // Wait for all
    Promise.all(promises).then($.proxy(function () {
      this.init();
    }, this), function (err) {
      console.error(err);
    });

  };

  Planets.prototype.init = function () {
    console.log("Init planets");

    // Create materials
    for (var i = 0; i < this.textures.length; i++) {
      var material = new THREE.MeshPhongMaterial();

      if (this.textures[i].color != false) {
        material.map = this.textures[i].color;
      }

      if (this.textures[i].bump != false) {
        material.bumpMap = this.textures[i].bump;
        material.bumpScale = 0.05;
      }

      if (this.textures[i].specular != false) {
        material.specularMap = this.textures[i].specular;
      }

      this.materials.push(material);

      // Create objects
      var geometry = new THREE.SphereGeometry(1, 100, 100);
      var sphere = new THREE.Mesh(geometry, this.materials[i]);

      var scale = this.p.worldData.planets[i].scale * this.baseScaleFactor;
      sphere.scale.set(scale, scale, scale);

      var x = window.getRandomInt(this.posMinX, this.posMaxX, true);
      var y = window.getRandomInt(this.posMinY, this.posMaxY, true);
      sphere.position.x = x;
      sphere.position.y = y;

      // sphere.position.x = this.p.worldData.planets[i].x;
      // sphere.position.y = this.p.worldData.planets[i].y;
      sphere.position.z = (-this.p.worldData.planets[i].z) * this.distanceFactor;

      sphere.rotation.x = Math.PI / 5;

      this.planets.push(sphere);

      this.p.scene.add(this.planets[i]);
    }
  }

  Planets.prototype.resetSinglePlanet = function (index) {
    var x = window.getRandomInt(this.posMinX, this.posMaxX, true);
    var y = window.getRandomInt(this.posMinY, this.posMaxY, true);
    this.planets[index].position.x = x;
    this.planets[index].position.y = y;

    this.planets[index].position.z = this.planets[this.planets.length - 1].position.z - (this.p.worldData.planets[index].z * this.distanceFactor);
  }

  Planets.prototype.animate = function () {
    for (var i = 0; i < this.planets.length; i++) {
      this.planets[i].rotation.y += 0.010;

      // Move towards camera
      this.planets[i].position.z += (this.speedFactor * this.p.globalSpeedFactor);

      // // Move based on controls
      this.planets[i].position.y += (this.movePlanetsY * this.moveSpeedFactor * (this.p.globalSpeedFactor / 2));
      this.planets[i].position.x += (this.movePlanetsX * this.moveSpeedFactor * (this.p.globalSpeedFactor / 2));


      // if planet hits sides, return to oposite direction
      // x
      // if (this.planets[i].position.x < this.boundaryMinX) {
      //   this.planets[i].position.x = this.boundaryMaxX;
      // }
      //
      // if (this.planets[i].position.x > this.boundaryMaxX) {
      //   this.planets[i].position.x = this.boundaryMinX;
      // }
      //
      // // y
      // if (this.planets[i].position.y < this.boundaryMinY) {
      //   this.planets[i].position.y = this.boundaryMaxY;
      // }
      //
      // if (this.planets[i].position.y > this.boundaryMaxY) {
      //   this.planets[i].position.y = this.boundaryMinY;
      // }
      //
      //
      if (this.planets[i].position.z > this.posMaxZ) {
        this.resetSinglePlanet(i);
      }
    }
  }

  Planets.prototype.updateDirection = function (params) {
    this.movePlanetsX = -params.x;
    this.movePlanetsY = params.y;
  }

  Planets.prototype.sendMessage = function (type, parameter) {

  };

  Planets.prototype.reset = function () {

  };


  // Expose Planets
  window.Planets = Planets;

})(jQuery, Promise);
