(function ($, Promise) {

  // Constructor
  function Meteorites(p) {

    this.p = p;

    this.meteorites = [];
    this.textures = [];
    this.materials = [];

    this.moveMeteoritesX = 0;
    this.moveMeteoritesY = 0;

    this.moveSpeedFactor = 2;
    this.speedFactor = 10;

    this.posMinX = -50000;
    this.posMaxX = 50000;
    this.posMinY = -25000;
    this.posMaxY = 25000;
    this.posMinZ = -500000;
    this.posMaxZ = this.p.camera.position.z;

    this.minScale = 1;
    this.maxScale = 250;

    // Load data then init
    this.loadData();
  }

  Meteorites.prototype.loadData = function () {
    console.log("Load meteorites data");

    var promises = [];

    // Load objects
    for (var i = 0; i < this.p.worldData.meteorites.objects.length; i++) {

      promises.push(new Promise($.proxy(function (index, resolve, reject) {

          var directory = this.p.worldData.meteorites.objects[i].directory;
          var modelFilename = this.p.worldData.meteorites.objects[i].model;
          var materialFilename = this.p.worldData.meteorites.objects[i].material;

          new THREE.MTLLoader()
            .setPath(directory)
            .load(materialFilename, $.proxy(function (materials) {

              // Load textures
              materials.preload();

              // Load model
              new THREE.OBJLoader()
                .setMaterials(materials)
                .setPath(directory)
                .load(modelFilename, $.proxy(function (object) {
                  this.meteorites.push(object);
                  resolve();
                }, this), undefined, function (err) {
                  reject('An error happened while loading meteorites objects.', err);
                });
            }, this));
        }, this, i)
      ));

    }

    // Wait for all
    Promise.all(promises).then($.proxy(function () {
      this.init();
    }, this), function (err) {
      console.error(err);
    });

  };

  Meteorites.prototype.init = function () {
    console.log("Init meteorites");

    // add more meteorites
    var counter = 0;
    while (this.meteorites.length < this.p.worldData.meteorites.count) {
      this.meteorites.push(this.meteorites[counter].clone());

      counter++;
      if (counter == this.meteorites.length) {
        counter = 0;
      }
    }

    for (var i = 0; i < this.meteorites.length; i++) {

      this.resetSingleMeteorite(i);

      // reset puts them at the back but let's spread them on load
      var z = window.getRandomInt(this.posMinZ, this.p.camera.position.z, true);

      this.meteorites[i].position.z = z;

      this.p.scene.add(this.meteorites[i]);
    }
  }

  Meteorites.prototype.resetSingleMeteorite = function (index) {
    var x = window.getRandomInt(this.posMinX, this.posMaxX, true);
    var y = window.getRandomInt(this.posMinY, this.posMaxY, true);
    var z = window.getRandomInt(this.posMinZ, this.posMinZ + 100000, true);

    this.meteorites[index].position.x = x;
    this.meteorites[index].position.y = y;
    this.meteorites[index].position.z = z;

    var scale = window.getRandomFloat(this.minScale, this.maxScale, true);

    this.meteorites[index].scale.set(scale, scale, scale);
  }

  Meteorites.prototype.animate = function () {
    for (var i = 0; i < this.meteorites.length; i++) {
      this.meteorites[i].rotation.z += 0.02;
      this.meteorites[i].rotation.x += 0.01;

      // Move towards camera
      this.meteorites[i].position.z += (this.speedFactor * this.p.globalSpeedFactor);

      // Move based on controls
      this.meteorites[i].position.y += (this.moveMeteoritesY * this.moveSpeedFactor * this.p.globalSpeedFactor);
      this.meteorites[i].position.x += (this.moveMeteoritesX * this.moveSpeedFactor * this.p.globalSpeedFactor);

      // if meteorite hits sides, return to oposite direction
      // x
      // if (this.meteorites[i].position.x < this.posMinX) {
      //   this.meteorites[i].position.x = this.posMaxX;
      // }
      //
      // if (this.meteorites[i].position.x > this.posMaxX) {
      //   this.meteorites[i].position.x = this.posMinX;
      // }
      //
      // // y
      // if (this.meteorites[i].position.y < this.posMinY) {
      //   this.meteorites[i].position.y = this.posMaxY;
      // }
      //
      // if (this.meteorites[i].position.y > this.posMaxY) {
      //   this.meteorites[i].position.y = this.posMinY;
      // }
      //
      if (this.meteorites[i].position.z > this.posMaxZ) {
        this.resetSingleMeteorite(i);
      }
    }
  }

  Meteorites.prototype.updateDirection = function (params) {
    this.moveMeteoritesX = -params.x;
    this.moveMeteoritesY = params.y;
  }

  Meteorites.prototype.sendMessage = function (type, parameter) {

  };

  Meteorites.prototype.reset = function () {

  };


  // Expose Meteorites
  window.Meteorites = Meteorites;

})(jQuery, Promise);
