(function ($, Promise) {

  // Constructor
  function UIElement(id, svg) {

    // Save game id
    // this.id = id;
    this.domElement = $("<div class='ui-element' id='" + id + "'></div>")
    this.svgElement = false;
    this.height = 0;
    this.currentProgress = 0;

    // Load data then init
    this.loadSvg(svg);
  }

  UIElement.prototype.loadSvg = function (svg) {

    // Load JSON for ZONES
    var json1 = $.ajax({
      url: svg,
      // dataType: 'json',
      cache: false,
      complete: $.proxy(function (data) {
        this.initGame(data.responseText);
      }, this)
    });

    // Wait for all
    // Promise.all([
    // 	json1.promise(),
    // 	// json2.promise(),
    // 	// json3.promise(),
    // ]).then($.proxy(function () {
    // 	this.initGame();
    // }, this));

  };

  UIElement.prototype.initGame = function (svgData) {

    this.svgElement = $(svgData);

    this.svgElement.find("path, circle, rect, polygon, g").each(function (i, n) {

      var showAt = $(n).data("progress-show-at");
      var follow = ($(n).data("progress-follow") == true);
      var rotateOrientation = ($(n).data("progress-rotate"));

      if (showAt) {
        $(n).addClass("progress-show-at")
      }
      if (follow) {
        $(n).addClass("progress-follow")
      }
      if (rotateOrientation) {
        $(n).addClass("progress-rotate-" + rotateOrientation)
      }
    })

    // counterclockwise
    this.domElement.append(this.svgElement);

    // Save height
    this.height = $(this.svgElement).height();
  };

  UIElement.prototype.setProgress = function (progress) {

    // console.log("got progres", progress)

    // Wait for the element to be loaded
    if (this.svgElement == false) {
      return;
    }

    // Update current progress
    this.currentProgress += (progress - this.currentProgress) / 10;

    // Update show-at objects
    this.svgElement.find(".progress-show-at").each($.proxy(function (i, n) {
      var showAt = $(n).data("progress-show-at");
      $(n).toggle(showAt < this.currentProgress);
    }, this));

    // Update follow objects
    this.svgElement.find(".progress-follow").each($.proxy(function (i, n) {
      var usableHeight = this.height - n.getBoundingClientRect().height;
      $(n).css("transform", "translate(0, " + (usableHeight - usableHeight * this.currentProgress) + "px)");
    }, this));

    // Update rotate objects
    this.svgElement.find(".progress-rotate-counterclockwise").each($.proxy(function (i, n) {
      $(n).css({
        "transform": "rotate(" + (360 - 360 * this.currentProgress) + "deg)",
        "transform-origin": "center center",
      });
    }, this));

    this.svgElement.find(".progress-rotate-clockwise").each($.proxy(function (i, n) {
      $(n).css({
        "transform": "rotate(" + (360 * this.currentProgress) + "deg)",
        "transform-origin": "center center",
      });
    }, this));
  };

  UIElement.prototype.reset = function () {

  };


  // Expose UIElement
  window.UIElement = UIElement;

})(jQuery, Promise);
