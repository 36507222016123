THREE.DistorPass = {

  uniforms: {

    "tDiffuse": {type: "t", value: null},
    "distortWave": {type: "f", value: 0},
    "distortForce": {type: "f", value: 0.02},

  },

  // 0.2126 R + 0.7152 G + 0.0722 B
  // vertexshader is always the same for postprocessing steps
  vertexShader: [

    // pass in our custom uniforms
    "uniform float distortWave;",
    "uniform float distortForce;",

    "varying vec2 vUv;",

    "void main() {",

    "vUv = uv;",
    "gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );",

    "}"

  ].join("\n"),

  fragmentShader: [

    // pass in our custom uniforms
    "uniform float distortWave;",
    "uniform float distortForce;",

    // pass in the image/texture we'll be modifying
    "uniform sampler2D tDiffuse;",

    // used to determine the correct texel we're working on
    "varying vec2 vUv;",

    // executed, in parallel, for each pixel
    "void main() {",

    // get the pixel from the texture we're working with (called a texel)
    // "vec4 texel = texture2D( tDiffuse, vUv );",

    //Center the cooordinates
    "vec2 uv = vUv;",
    "uv += 0.5;",

    //Polar coordinates
    "float r = length(uv);",
    "float theta = atan(uv.y, uv.x);",

    //Make a pulse down the radius
    "float pulse = sin(20.0 * r - 5.0 * distortWave) * distortWave;",

    //Convert Polar -> Rectangular
    "vec2 pulse_rect = vec2(pulse * cos(theta), pulse * sin(theta));",

    //use the pulse to distort the texture.
    "vec4 fragColor = texture2D(tDiffuse, vUv + distortForce * pulse_rect);",
    "fragColor.r = fragColor.r + (distortWave * fragColor.r);",
    "fragColor.g = fragColor.g + (distortWave * fragColor.g);",
    "fragColor.b = fragColor.b + (distortWave * fragColor.b);",

    // return this new color
    "gl_FragColor = fragColor;",

    "}"

  ].join("\n")

};
