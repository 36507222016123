(function ($, Promise) {

  // Constructor
  function Debris(p) {

    this.p = p;
    this.sprites = [];

    this.debris = new THREE.Group();
    this.debrisMaterials = []
    this.debrisGeometries = [];

    this.moveDebrisX = 0;
    this.moveDebrisY = 0;

    this.speedFactor = 0.25;
    this.debrisCount = 100;

    this.debrisPosMinX = -1000;
    this.debrisPosMaxX = 1000;
    this.debrisPosMinY = -1000;
    this.debrisPosMaxY = 1000;
    this.debrisPosMinZ = -1000;
    this.debrisPosMaxZ = this.p.camera.position.z;

    // Load data then init
    this.loadData();
  }

  Debris.prototype.loadData = function () {
    console.log("Load debris data");

    var promises = [];

    for (var i = 0; i < this.p.worldData.debris.sprites.length; i++) {

      promises.push(new Promise($.proxy(function (resolve, reject) {
        // Load textures
        new THREE.TextureLoader().load(this.p.worldData.debris.sprites[i],
          // onLoad callback
          $.proxy(function (texture) {
            this.sprites.push(texture);

            resolve();
          }, this),

          // onProgress callback currently not supported
          undefined,

          // onError callback
          function (err) {
            reject('An error happened while loading debris sprite.', err);
          });
      }, this)));
    }

    // Wait for all
    Promise.all(promises).then($.proxy(function () {
      this.init();
    }, this), function (err) {
      console.error(err);
    });

  };

  Debris.prototype.init = function () {
    console.log("Init debris");

    var parameters = [];
    for (var i = 0; i < this.sprites.length; i++) {
      // Add each sprites 3 times in different colors
      parameters.push([[1.0, 1.0, 1.0], this.sprites[i], 10]);
      parameters.push([[0.27, 0.27, 0.27], this.sprites[i], 15]);
      parameters.push([[0.25, 0.13, 0.0], this.sprites[i], 20]);
    }

    for (var i = 0; i < parameters.length; i++) {
      var geometry = new THREE.BufferGeometry();

      var vertices = [];
      for (var j = 0; j < Math.round(this.debrisCount / parameters.length); j++) {
        var x = window.getRandomInt(this.debrisPosMinX, this.debrisPosMaxX, true);
        var y = window.getRandomInt(this.debrisPosMinY, this.debrisPosMaxY, true);
        var z = window.getRandomInt(this.debrisPosMinZ, this.debrisPosMaxZ, true);
        vertices.push(x, y, z);
      }

      geometry.addAttribute('position', new THREE.Float32BufferAttribute(vertices, 3));

      this.debrisGeometries[i] = geometry;

      var color = parameters[i][0];
      var sprite = parameters[i][1];
      var size = parameters[i][2];

      this.debrisMaterials[i] = new THREE.PointsMaterial({
        size: size,
        map: sprite,
        blending: THREE.AdditiveBlending,
        depthTest: false,
        transparent: false
      });

      this.debrisMaterials[i].color.setRGB(color[0], color[1], color[2]);
      this.debrisMaterials[i].opacity = 1;

      var particles = new THREE.Points(this.debrisGeometries[i], this.debrisMaterials[i]);

      // rotate particles towards camera
      particles.rotation.z = -Math.PI / 2;

      this.debris.add(particles);
    }

    this.p.scene.add(this.debris);

  }

  Debris.prototype.animate = function () {
    for (var g = 0; g < this.debrisGeometries.length; g++) {
      // move debris towards camera
      var positions = this.debrisGeometries[g].attributes.position.array;

      for (var i = 0; i < positions.length; i += 3) {
        // i = y
        // i + 1 = x
        // i + 2 = z

        // move debris according to controls
        positions[i] += (this.moveDebrisY * this.speedFactor);
        positions[i + 1] += (this.moveDebrisX * this.speedFactor);

        // move debris towards camera;
        positions[i + 2] += (this.speedFactor * this.p.globalSpeedFactor);

        // if debris hits sides, return to oposite direction
        // x
        if (positions[i + 1] < this.debrisPosMinX) {
          positions[i + 1] = this.debrisPosMaxX;
        }

        if (positions[i + 1] > this.debrisPosMaxX) {
          positions[i + 1] = this.debrisPosMinX;
        }

        // y
        if (positions[i] < this.debrisPosMinY) {
          positions[i] = this.debrisPosMaxY;
        }

        if (positions[i] > this.debrisPosMaxY) {
          positions[i] = this.debrisPosMinY;
        }

        // if debris is at camera (1000, the camera Z position), reset it's position
        if (positions[i + 2] > this.debrisPosMaxZ) {
          // reset

          // restart at a random x,y position
          var x = window.getRandomInt(this.debrisPosMinX, this.debrisPosMaxX, true);
          var y = window.getRandomInt(this.debrisPosMinY, this.debrisPosMaxY, true);
          positions[i] = x;
          positions[i + 1] = y;

          // reset at the back
          positions[i + 2] = this.debrisPosMinZ;
        }
      }

      this.debrisGeometries[g].attributes.position.needsUpdate = true;
    }
  }

  Debris.prototype.updateDirection = function (params) {
    this.moveDebrisX = -params.x; // need to do the inverse to simulate that the ship is moving
    this.moveDebrisY = -params.y; // need to do the inverse to simulate that the ship is moving
  }

  Debris.prototype.sendMessage = function (type, parameter) {

  };

  Debris.prototype.reset = function () {

  };


  // Expose Debris
  window.Debris = Debris;

})(jQuery, Promise);
