(function ($, Promise) {

  // Constructor
  function Ships(p) {

    this.p = p;

    this.ships = [];
    this.materials = [];

    this.moveShipsX = 0;
    this.moveShipsY = 0;

    this.moveSpeedFactor = 0.75;
    this.speedFactor = 0.3;
    this.lateralSpeedFactor = 30;

    this.posMinX = -20000;
    this.posMaxX = 20000;
    this.posMinY = -1000;
    this.posMaxY = 1000;
    this.posMinZ = -100000;
    this.posMaxZ = this.p.camera.position.z;

    this.possibleActions = ["from_left", "from_right", "from_back", "from_front"]

    // Load data then init
    this.loadData();
  }

  Ships.prototype.loadData = function () {
    console.log("Load ships data");

    var promises = [];

    // Load objects
    for (var i = 0; i < this.p.worldData.ships.objects.length; i++) {

      promises.push(new Promise($.proxy(function (index, resolve, reject) {

          var directory = this.p.worldData.ships.objects[index].directory;
          var modelFilename = this.p.worldData.ships.objects[index].model;
          var materialFilename = this.p.worldData.ships.objects[index].material;

          new THREE.MTLLoader()
            .setPath(directory)
            .load(materialFilename, $.proxy(function (materials) {

              // Load textures
              materials.preload();

              // Load model
              new THREE.OBJLoader()
                .setMaterials(materials)
                .setPath(directory)
                .load(modelFilename, $.proxy(function (object) {
                  object.isShip = true;
                  object.baseScale = this.p.worldData.ships.objects[index].scale;
                  object.baseRotation = this.p.worldData.ships.objects[index].rotation;
                  object.rotationAxis = this.p.worldData.ships.objects[index].rotationAxis;
                  object.rotationSpeed = this.p.worldData.ships.objects[index].rotationSpeed;
                  object.rotationDirection = 1;
                  object.action = this.possibleActions[window.getRandomInt(0, this.possibleActions.length - 1, true)];
                  this.ships.push(object);
                  resolve();
                }, this), undefined, function (err) {
                  reject('An error happened while loading ships objects.', err);
                });
            }, this));
        }, this, i)
      ));

    }

    // Wait for all
    Promise.all(promises).then($.proxy(function () {
      this.init();
    }, this), function (err) {
      console.error(err);
    });

  };

  Ships.prototype.init = function () {
    console.log("Init ships");

    for (var i = 0; i < this.ships.length; i++) {

      this.resetSingleShip(i);

      // reset puts them at the back but let's spread them on load
      // var z = window.getRandomInt(this.posMinZ, this.p.camera.position.z, true);

      // this.ships[i].position.z = z;

      this.p.scene.add(this.ships[i]);
    }
  }

  Ships.prototype.resetSingleShip = function (index) {

    this.ships[index].rotation.x = this.ships[index].baseRotation.x;
    this.ships[index].rotation.y = this.ships[index].baseRotation.y;
    this.ships[index].rotation.z = this.ships[index].baseRotation.z;

    // var scale = window.getRandomFloat(this.minScale, this.maxScale, true);
    var scale = this.ships[index].baseScale;

    this.ships[index].scale.set(scale, scale, scale);

    this.ships[index].rotationDirection *= -1; // invert direction to add more "randomness"

    // Set a new action
    this.ships[index].lastAction = this.ships[index].action;
    var currentAction = this.possibleActions[window.getRandomInt(0, this.possibleActions.length - 1, true)];

    // prevent using the same action as before
    while (this.ships[index].lastAction == currentAction) {
      currentAction = this.possibleActions[window.getRandomInt(0, this.possibleActions.length - 1, true)];
    }

    this.ships[index].action = currentAction;


    switch (this.ships[index].action) {
      case "from_left":
        this.ships[index].position.x = window.getRandomInt(this.posMinX - 10000, this.posMinX, true);
        this.ships[index].position.y = window.getRandomInt(this.posMinY, this.posMaxY, true);
        this.ships[index].position.z = window.getRandomInt(-20000, -10000, true);
        this.ships[index].rotation.y += Math.PI / 2;
        break;
      case "from_right":
        this.ships[index].position.x = window.getRandomInt(this.posMaxX, this.posMaxX + 10000, true);
        this.ships[index].position.y = window.getRandomInt(this.posMinY, this.posMaxY, true);
        this.ships[index].position.z = window.getRandomInt(-20000, -10000, true);
        this.ships[index].rotation.y -= Math.PI / 2;
        break;
      case "from_back":
        this.ships[index].position.x = window.getRandomInt(-7500, 7500, true);
        this.ships[index].position.y = window.getRandomInt(this.posMinY, this.posMaxY, true);
        this.ships[index].position.z = window.getRandomInt(this.p.camera.position.z, this.p.camera.position.z + 10000, true);
        this.ships[index].rotation.y -= Math.PI;
        break;
      case "from_front":
        this.ships[index].position.x = window.getRandomInt(-7500, 7500, true);
        this.ships[index].position.y = window.getRandomInt(this.posMinY, this.posMaxY, true);
        this.ships[index].position.z = window.getRandomInt(this.posMinZ, this.posMinZ - 10000, true);
        break;
    }

    // set the material
    // this.ships[index].traverse($.proxy(function (child) {
    //   if (child instanceof THREE.Mesh) {
    //     // assign a random material to the meteorite
    //     child.material = this.materials[window.getRandomInt(0, this.materials.length - 1, true)];
    //   }
    // }, this));
  }

  Ships.prototype.animate = function () {
    for (var i = 0; i < this.ships.length; i++) {

      if (this.ships[i].rotationAxis != false) {
        this.ships[i].rotation[this.ships[i].rotationAxis] += (this.ships[i].rotationDirection * this.ships[i].rotationSpeed);
      }


      switch (this.ships[i].action) {
        case "from_left":
          this.animateFromLeft(this.ships[i], i);
          break;
        case "from_right":
          this.animateFromRight(this.ships[i], i);
          break;
        case "from_back":
          this.animateFromBack(this.ships[i], i);
          break;
        case "from_front":
          this.animateFromFront(this.ships[i], i);
          break;
      }

      // Move towards camera
      // this.ships[i].position.z += (this.speedFactor * this.p.globalSpeedFactor);

      // Move based on controls
      this.ships[i].position.y += (this.moveShipsY * this.moveSpeedFactor * (this.p.globalSpeedFactor / 2));
      this.ships[i].position.x += (this.moveShipsX * this.moveSpeedFactor * (this.p.globalSpeedFactor / 2));

      // // if debris hits sides, return to oposite direction
      // // x
      // if (this.ships[i].position.x < this.posMinX) {
      //   this.ships[i].position.x = this.posMaxX;
      // }
      //
      // if (this.ships[i].position.x > this.posMaxX) {
      //   this.ships[i].position.x = this.posMinX;
      // }
      //
      // // y
      // if (this.ships[i].position.y < this.posMinY) {
      //   this.ships[i].position.y = this.posMaxY;
      // }
      //
      // if (this.ships[i].position.y > this.posMaxY) {
      //   this.ships[i].position.y = this.posMinY;
      // }

      // if (this.ships[i].position.z > this.posMaxZ) {
      //   this.resetSingleShip(i);
      // }
    }
  }

  Ships.prototype.animateFromLeft = function (ship, index) {
    // Move to the right
    ship.position.x += this.lateralSpeedFactor;
    // animate a little bit towards camera too because we are going forward
    ship.position.z += ((this.speedFactor * this.p.globalSpeedFactor) / 5);

    if (ship.position.x > this.posMaxX) {
      this.resetSingleShip(index);
    }
  }

  Ships.prototype.animateFromRight = function (ship, index) {
    // Move to the right
    ship.position.x -= this.lateralSpeedFactor;
    // animate a little bit towards camera too because we are going forward
    ship.position.z += ((this.speedFactor * this.p.globalSpeedFactor) / 5);

    if (ship.position.x < this.posMinX) {
      this.resetSingleShip(index);
    }
  }

  Ships.prototype.animateFromBack = function (ship, index) {
    // Move away from camera
    if (this.p.screenBlurAmount > 0) {
      // this is like we are going super fast so we should go past the ships
      ship.position.z += ((this.speedFactor * this.p.globalSpeedFactor) / 5);
    } else {
      ship.position.z -= (this.speedFactor * this.p.globalSpeedFactor);
    }

    if (ship.position.z < this.posMinZ) {
      this.resetSingleShip(index);
    }
  }

  Ships.prototype.animateFromFront = function (ship, index) {
    // Move towards camera
    ship.position.z += (this.speedFactor * this.p.globalSpeedFactor);

    if (ship.position.z > this.posMaxZ) {
      this.resetSingleShip(index);
    }
  }

  Ships.prototype.updateDirection = function (params) {
    this.moveShipsX = -params.x;
    this.moveShipsY = params.y;
  }

  Ships.prototype.sendMessage = function (type, parameter) {

  };

  Ships.prototype.reset = function () {

  };


  // Expose Ships
  window.Ships = Ships;

})(jQuery, Promise);
