(function ($, Promise) {

  // Constructor
  function Stars(p) {

    this.p = p;

    this.textureLoader = new THREE.TextureLoader();
    this.sprites = [];

    this.stars = new THREE.Group();
    this.starsMaterials = []
    this.starsGeometries = [];

    this.moveStarsX = 0;
    this.moveStarsY = 0;

    this.speedFactor = 0.25;
    this.starsCount = 5000;
    this.starPosMinX = -1000;
    this.starPosMaxX = 1000;
    this.starPosMinY = -1000;
    this.starPosMaxY = 1000;
    this.starPosMinZ = -2000;
    this.starPosMaxZ = -1000;

    // Load data then init
    this.loadData();
  }

  Stars.prototype.loadData = function () {
    console.log("Load stars data");

    var promises = [];

    for (var i = 0; i < this.p.worldData.stars.sprites.length; i++) {

      promises.push(new Promise($.proxy(function (resolve, reject) {
        // Load textures
        this.textureLoader.load(this.p.worldData.stars.sprites[i],
          // onLoad callback
          $.proxy(function (texture) {
            this.sprites.push(texture);

            resolve();
          }, this),

          // onProgress callback currently not supported
          undefined,

          // onError callback
          function (err) {
            reject('An error happened while loading star sprite.', err);
          });
      }, this)));
    }

    // Wait for all
    Promise.all(promises).then($.proxy(function () {
      this.init();
    }, this), function (err) {
      console.error(err);
    });

  };

  Stars.prototype.init = function () {
    console.log("Init stars");

    var parameters = [];
    for (var i = 0; i < this.sprites.length; i++) {
      // Add each sprites 3 times in different colors
      parameters.push([[1.0, 1.0, 1.0], this.sprites[i], 10]);
      parameters.push([[0.7, 0.6, 0.2], this.sprites[i], 15]);
      parameters.push([[0.2, 0.2, 0.5], this.sprites[i], 20]);
    }

    for (var i = 0; i < parameters.length; i++) {
      var geometry = new THREE.BufferGeometry();

      var vertices = [];
      for (var j = 0; j < Math.round(this.starsCount / parameters.length); j++) {
        var x = window.getRandomInt(this.starPosMinX, this.starPosMaxX, true);
        var y = window.getRandomInt(this.starPosMinY, this.starPosMaxY, true);
        var z = window.getRandomInt(this.starPosMinZ, this.starPosMaxZ, true);
        vertices.push(x, y, z);
      }

      geometry.addAttribute('position', new THREE.Float32BufferAttribute(vertices, 3));

      this.starsGeometries[i] = geometry;

      var color = parameters[i][0];
      var sprite = parameters[i][1];
      var size = parameters[i][2] * 10;

      this.starsMaterials[i] = new THREE.PointsMaterial({
        size: size,
        map: sprite,
        blending: THREE.AdditiveBlending,
        depthTest: false,
        transparent: false
      });

      this.starsMaterials[i].color.setRGB(color[0], color[1], color[2]);
      this.starsMaterials[i].opacity = 1;

      var particles = new THREE.Points(this.starsGeometries[i], this.starsMaterials[i]);

      // rotate particles towards camera
      particles.rotation.z = -Math.PI / 2;

      this.stars.add(particles);
    }

    this.p.scene.add(this.stars);

  }

  Stars.prototype.animate = function () {
    for (var g = 0; g < this.starsGeometries.length; g++) {
      // move stars towards camera
      var positions = this.starsGeometries[g].attributes.position.array;

      for (var i = 0; i < positions.length; i += 3) {
        // i = y
        // i + 1 = x
        // i + 2 = z

        // move stars according to controls
        positions[i] += (this.moveStarsY * this.speedFactor);
        positions[i + 1] += (this.moveStarsX * this.speedFactor);

        // move star towards camera;
        // positions[i + 2] += (this.speedFactor * this.p.globalSpeedFactor);

        // if star hits sides, return to oposite direction
        // x
        if (positions[i + 1] < this.starPosMinX) {
          positions[i + 1] = this.starPosMaxX;
        }

        if (positions[i + 1] > this.starPosMaxX) {
          positions[i + 1] = this.starPosMinX;
        }

        // y
        if (positions[i] < this.starPosMinY) {
          positions[i] = this.starPosMaxY;
        }

        if (positions[i] > this.starPosMaxY) {
          positions[i] = this.starPosMinY;
        }

        // // if star is at camera (1000, the camera Z position), reset it's position
        // if (positions[i + 2] > this.starPosMaxZ) {
        //   // reset
        //
        //   // restart at a random x,y position
        //   var x = window.getRandomInt(this.starPosMinX, this.starPosMaxX, true);
        //   var y = window.getRandomInt(this.starPosMinY, this.starPosMaxY, true);
        //   positions[i] = x;
        //   positions[i + 1] = y;
        //
        //   // reset at the back
        //   positions[i + 2] = this.starPosMinZ;
        // }
      }

      this.starsGeometries[g].attributes.position.needsUpdate = true;
    }
  }

  Stars.prototype.updateDirection = function (params) {
    this.moveStarsX = -params.x; // need to do the inverse to simulate that the ship is moving
    this.moveStarsY = -params.y; // need to do the inverse to simulate that the ship is moving
  }

  Stars.prototype.sendMessage = function (type, parameter) {

  };

  Stars.prototype.reset = function () {

  };


  // Expose Stars
  window.Stars = Stars;

})(jQuery, Promise);
